import React, { useState, useEffect, ReactElement } from "react";
// Icons imports
import { Header } from "../landingPage/Header";
import { Footer } from "../landingPage/Footer";
import { Main } from "../landingPage/Main";
import CustomContentDisplayer from "../layouts/CustomContentDisplayer";
import strings from "../../assets/strings";
import Modal from "../layouts/Modal";
import { ModalTypeEnum } from "../../types/ModalTypeEnum";
import CookiesUserRequestView from "../layouts/CookiesUserRequestView";
import { ContextualViewTypeEnum } from "../../types/ContextualViewTypeEnum";
import { HelpQuestionsSectionsEnum } from "../../types/helpQuestionsSectionsEnum";

export const LandingPage = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Asking for actions modal
  const [actionModalOpened, setActionModalOpened] = useState(false);
  const [contextualViewType, setContextualViewType] =
    useState<ContextualViewTypeEnum>(ContextualViewTypeEnum.Nothing);
  const [modalType, setModalType] = useState<ModalTypeEnum>(
    ModalTypeEnum.Nothing
  );
  const [sessionCookies, setSessionCookiesValue] = useState<string | null>(
    localStorage.getItem("session_has_cookies")
  );
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const [clickedId, setClickedId] = useState("null");

  const handleFooterClick = (id: any) => {
    setClickedId(id);
    toggleFullScreen();
  };

  const [clickedHeaderId, setClickedHeaderId] = useState(null);

  const handleHeaderClick = (id: any) => {
    if (id === "help") {
      setClickedId(id);
      toggleFullScreen();
    } else {
      setClickedHeaderId(id);
      scrollToSection(id);
    }
  };

  function enableAccordion(item: HelpQuestionsSectionsEnum) {
    if (item === HelpQuestionsSectionsEnum.WhatSection) {
      setActiveItem("What");
    } else if (item === HelpQuestionsSectionsEnum.WhySection) {
      setActiveItem("Why");
    } else if (item === HelpQuestionsSectionsEnum.HowSection) {
      setActiveItem("How");
    } else if (item === HelpQuestionsSectionsEnum.DoctorSection) {
      setActiveItem("HowDoctor");
    } else if (item === HelpQuestionsSectionsEnum.SupportSection) {
      setActiveItem("Support");
    } else if (item === HelpQuestionsSectionsEnum.ContactSection) {
      setActiveItem("Contact");
    } else {
      setActiveItem(null);
    }
  }

  interface Content {
    type: ContextualViewTypeEnum;
    titre: string;
    contenu: ReactElement;
  }

  function getContentById(id: string): Content {
    switch (id) {
      case "confidentialite":
        return {
          type: ContextualViewTypeEnum.DataProtection,
          titre: "Politique de confidentialité",
          contenu: (
            <div>Ceci est le contenu de la politique de confidentialité</div>
          ),
        };
      case "legalMentions":
        return {
          type: ContextualViewTypeEnum.LegalMentions,
          titre: "Mentions légales et conditions générales d'utilisation",
          contenu: <div>Ceci est le contenu des conditions d'utilisation</div>,
        };
      case "cookies":
        return {
          type: ContextualViewTypeEnum.Cookies,
          titre: "Politique en matière de cookies",
          contenu: <div>Ceci est le contenu de la politique des cookies</div>,
        };
      case "help":
        return {
          type: ContextualViewTypeEnum.Help,
          titre: "Besoin d'aide ?",
          contenu: <div>Ceci est le contenu de la politique des cookies</div>,
        };
      default:
        return {
          type: ContextualViewTypeEnum.Nothing,
          titre: "",
          contenu: <div></div>,
        };
    }
  }

  useEffect(() => {
    document.title = strings.welcome_title_tab;
    if (sessionCookies === "" || sessionCookies === null) {
      setActionModalOpened(true);
    }
  }, []);

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isFullScreen]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { type, titre, contenu } = getContentById(clickedId);

  return (
    <>
      <div className="w-full">
        <Header onHeaderClick={handleHeaderClick} />
        <Main />
        <Footer onFooterClick={handleFooterClick} />

        {/* Ask user for action with modal */}
        {/*
                    <Modal
                         open={actionModalOpened}
                         title=""
                         onClose={() => setActionModalOpened(false)}>

                         <CookiesUserRequestView
                              onCloseClick={() => {
                                   localStorage.setItem("session_has_cookies", "no")
                                   setSessionCookiesValue("no")
                                   setActionModalOpened(false)
                              }}
                              onConfirmClick={() => {
                                   localStorage.setItem("session_has_cookies", "yes")
                                   setSessionCookiesValue("yes")
                                   setActionModalOpened(false)
                              }}
                              isActionsDisabled={false}
                              emails={[]}
                              handleEmailsSelectionChange={() => {
                                   //setModalType(ModalTypeEnum.Nothing)
                                   //setActionModalOpened(false)
                              }}
                         />
                    </Modal>
                    */}
        <div
          className={`fixed bottom-0 left-0 right-0 bg-white z-50 transition-all ${
            isFullScreen
              ? "h-full duration-300 overflow-auto"
              : "h-0 duration-300 sm:h-0 duration-300 md:h-0 duration-300 lg:h-0 duration-300"
          }`}
        >
          <div className="fixed z-30 flex flex-col w-full px-4 mx-auto bg-white md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className={"flex flex-grow items-center"}>
              <nav className="flex-col flex-grow ">
                <ul className="flex flex-wrap items-center justify-start flex-grow pt-4">
                  <li>
                    <button
                      onClick={() => {
                        toggleFullScreen();
                      }}
                      className="flex items-center bg-white text-[#1E6091] font-semibold py-1"
                    >
                      <svg
                        fill="#1E6091"
                        className="w-6 h-6 mr-3"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 386.242 386.242"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke="#1E6091"
                          strokeWidth="0.7724840000000001"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <path
                              id="Arrow_Back"
                              d="M374.212,182.3H39.432l100.152-99.767c4.704-4.704,4.704-12.319,0-17.011 c-4.704-4.704-12.319-4.704-17.011,0L3.474,184.61c-4.632,4.632-4.632,12.379,0,17.011l119.1,119.1 c4.704,4.704,12.319,4.704,17.011,0c4.704-4.704,4.704-12.319,0-17.011L39.432,206.36h334.779c6.641,0,12.03-5.39,12.03-12.03 S380.852,182.3,374.212,182.3z"
                            ></path>{" "}
                            <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                            <g> </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                      <span className="pl-1">Retour</span>
                    </button>
                  </li>
                </ul>
                <div>
                  <label className="block text-[#1E6091] font-medium text-sm mt-4 mb-4">
                    <h6 className="flex items-center text-xl text-left font-display">
                      {titre}
                    </h6>
                  </label>
                </div>
              </nav>
            </div>
          </div>
          <CustomContentDisplayer>
            <div className="px-8 pt-24 mx-auto">
              <div className="mx-auto">
                <div className="gap-y-8 lg:gap-y-0 lg:gap-x-6">
                  <div className="lg:col-span-2">
                    <div>
                      <div className="space-y-5 text-left lg:space-y-8">
                        {(() => {
                          switch (type) {
                            case ContextualViewTypeEnum.LegalMentions:
                              return (
                                <>
                                  <div className="flex flex-col w-full mt-10 mb-8 space-y-2">
                                    <div className="md:col-span-3">
                                      <h4 className="text-base font-bold text-left dark:text-white font-display">
                                        1. Éditeur de l’Application :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        DocTraitant est éditée par Angicia,
                                        société SAS au capital de 1000 €, dont
                                        le siège social est situé à Toulouse,
                                        inscrite au RCS de Toulouse sous le
                                        2424242424. Numéro de TVA
                                        intracommunautaire : 2424242424
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        2. Hébergement :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’application DocTraitant est hébergée
                                        par OVH SAS, 2, rue Kellermann, 59100
                                        Roubaix
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        3. Contact :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Pour toute question ou demande
                                        concernant l’utilisation de
                                        l’application, veuillez nous contacter à
                                        l’adresse suivante :
                                        contact@doctraitant.fr
                                      </p>

                                      <h4 className="mt-4 mb-4 text-base font-bold text-left dark:text-white font-display">
                                        Conditions Générales d’Utilisation (CGU)
                                      </h4>

                                      <h4 className="text-base font-bold text-left dark:text-white font-display">
                                        1. Objet :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les présentes conditions générales
                                        d’utilisation ont pour objet de définir
                                        les modalités et conditions
                                        d’utilisation de l’application
                                        DocTraitant, ainsi que de définir les
                                        droits et obligations des parties dans
                                        ce cadre.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        2. Accès et inscription :
                                      </h4>

                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’application est accessible :
                                      </p>
                                      <ul className="m-4 space-y-3 text-sm text-gray-800 list-disc list-outside ps-3 dark:text-gray-200">
                                        <li className="ps-1">
                                          À toute personne physique ayant au
                                          moins 18 ans et capable juridiquement
                                          de souscrire à des contrats en France.
                                          L’utilisation de l’application pour
                                          les mineurs doit être supervisée par
                                          un adulte ayant l’autorité parentale.
                                        </li>
                                        <li className="ps-1">
                                          À toute personne morale agissant par
                                          l’intermédiaire d’une personne
                                          physique ayant les pouvoirs
                                          nécessaires pour contracter au nom et
                                          pour le compte de la personne morale.
                                        </li>
                                      </ul>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        3. Services proposés :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’application DocTraitant permet à ses
                                        utilisateurs de trouver un médecin
                                        traitant à proximité de leur domicile,
                                        en fonction des informations fournies
                                        par les utilisateurs et disponibles dans
                                        la base de données de DocTraitant.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        4. Obligations des utilisateurs :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les utilisateurs s’engagent à fournir
                                        des informations véridiques et à jour
                                        lors de leur utilisation de
                                        l’application. Ils s’engagent à ne pas
                                        utiliser l’application à des fins
                                        illégales ou non autorisées.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        5. Propriété intellectuelle :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Tous les éléments de l’application
                                        DocTraitant, y compris les textes,
                                        graphiques, logos, sons, photos,
                                        animations, sont la propriété exclusive
                                        de l’éditeur, à l'exception des marques,
                                        logos ou contenus appartenant à d'autres
                                        sociétés partenaires ou auteurs.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        6. Responsabilité :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’éditeur ne saurait être tenu
                                        responsable des dommages directs ou
                                        indirects causés au matériel de
                                        l'utilisateur, lors de l’accès à
                                        l’application. L’éditeur décline toute
                                        responsabilité quant à l’utilisation qui
                                        pourrait être faite des informations et
                                        contenus présents sur DocTraitant.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        7. Modification des conditions :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’éditeur se réserve le droit de
                                        modifier les clauses de ces conditions
                                        générales d’utilisation à tout moment.
                                        En cas de modification, les nouvelles
                                        CGU seront applicables dès leur mise en
                                        ligne.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        8. Droit applicable et juridiction
                                        compétente :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les présentes conditions générales sont
                                        soumises au droit français. En cas de
                                        litige, les tribunaux français seront
                                        seuls compétents.
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            case ContextualViewTypeEnum.DataProtection:
                              return (
                                <>
                                  <div className="flex flex-col w-full mt-10 mb-8 space-y-2">
                                    <div className="md:col-span-3">
                                      <p className="text-sm text-gray-800 dark:text-gray-200">
                                        La société DocTraitant, en partenariat
                                        avec des organismes de santé accrédités
                                        et conformément aux régulations du
                                        Ministère des Solidarités et de la
                                        Santé, s’engage à assurer la protection,
                                        la confidentialité et la sécurité de vos
                                        données personnelles dans le cadre de
                                        l'utilisation de l'application
                                        "DocTraitant". Cette application inclut
                                        notamment des données de santé, qui
                                        bénéficient d'une protection spéciale en
                                        vertu de l'article L. 1110-4 du code de
                                        la santé publique. Tout manquement au
                                        secret médical entourant ces données est
                                        susceptible de sanctions pénales
                                        conformément à l'article 226-13 du code
                                        pénal. À travers notre application et
                                        sur toutes les interfaces permettant
                                        l’accès à "DocTraitant", nous appliquons
                                        la présente politique de protection des
                                        données personnelles. Cette politique a
                                        pour but de vous informer clairement sur
                                        la gestion de vos données et les mesures
                                        mises en place pour protéger ces
                                        dernières.
                                      </p>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        1. Collecte des données personnelles :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        L’utilisation de l’application
                                        DocTraitant implique la collecte
                                        d’informations personnelles fournies par
                                        les utilisateurs lors de leur
                                        inscription et de leur utilisation des
                                        services proposés. Les données
                                        collectées incluent notamment le nom,
                                        prénom, adresse e-mail, numéro de
                                        téléphone, adresse postale, et toute
                                        autre information de santé nécessaire à
                                        la mise en relation avec un médecin
                                        traitant.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        2. Utilisation des données personnelles
                                        :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les données personnelles collectées
                                        auprès des utilisateurs sont destinées à
                                        fournir les services de l’application, à
                                        améliorer ces services, à assurer une
                                        gestion efficace de la relation client,
                                        et, sous réserve de l’accord des
                                        utilisateurs, à les informer des offres
                                        et informations relatives à
                                        l’entreprise.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        3. Partage des données personnelles :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les données personnelles peuvent être
                                        partagées avec des tiers uniquement dans
                                        les cas suivants :
                                      </p>
                                      <ul className="m-4 space-y-3 text-sm text-gray-800 list-disc list-outside ps-3 dark:text-gray-200">
                                        <li className="ps-1">
                                          Avec les prestataires de services et
                                          sous-traitants réalisant des services
                                          en notre nom
                                        </li>
                                        <li className="ps-1">
                                          En cas de demande légale ou
                                          réglementaire, judiciaire
                                        </li>
                                      </ul>

                                      <h4 className="text-base font-bold text-left dark:text-white font-display">
                                        4. Sécurité et confidentialité :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        DocTraitant s’engage à mettre en œuvre
                                        toutes les mesures techniques et
                                        organisationnelles nécessaires pour
                                        protéger les données personnelles contre
                                        l’altération, la destruction
                                        accidentelle ou illégale, la perte, la
                                        divulgation ou l'accès non autorisé.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        5. Droits des utilisateurs :
                                      </h4>

                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Conformément à la législation européenne
                                        en vigueur, les utilisateurs de
                                        DocTraitant disposent des droits
                                        suivants concernant leurs données
                                        personnelles :
                                      </p>
                                      <ul className="m-4 space-y-3 text-sm text-gray-800 list-disc list-outside ps-3 dark:text-gray-200">
                                        <li className="ps-1">
                                          Droit d’accès et de rectification
                                        </li>
                                        <li className="ps-1">
                                          Droit à l’effacement
                                        </li>
                                        <li className="ps-1">
                                          Droit à la limitation du traitement
                                        </li>
                                        <li className="ps-1">
                                          Droit à la portabilité des données
                                        </li>
                                        <li className="ps-1">
                                          Droit d’opposition
                                        </li>
                                      </ul>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Ces droits peuvent être exercés
                                        directement par l’envoi d’une demande à
                                        l’adresse email data@doctraitant.fr ou
                                        par courrier postal à l'adresse suivante
                                        : Toulouse.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        6. Conservation des données :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Les données personnelles sont conservées
                                        pendant une durée qui n’excède pas la
                                        durée nécessaire aux fins pour
                                        lesquelles elles ont été collectées ou
                                        traitées. Cette durée peut toutefois
                                        être prolongée en cas de dispositions
                                        légales ou réglementaires l'exigeant.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        7. Modifications de la politique de
                                        protection des données :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Cette politique de protection des
                                        données peut être mise à jour à tout
                                        moment par DocTraitant. Les utilisateurs
                                        seront informés de toute modification
                                        substantielle via l’application ou par
                                        email.
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            case ContextualViewTypeEnum.Cookies:
                              return (
                                <>
                                  <div className="flex flex-col w-full mt-10 mb-8 space-y-2">
                                    <div className="md:col-span-3">
                                      <p className="text-sm text-gray-800 dark:text-gray-200">
                                        Chez Doctraitant, nous sommes déterminés
                                        à protéger votre vie privée tout en
                                        améliorant votre expérience sur notre
                                        plateforme. Les cookies nous permettent
                                        de comprendre comment notre site est
                                        utilisé et d'améliorer la qualité de nos
                                        services. Sur cette page, vous pouvez
                                        gérer vos préférences en matière de
                                        cookies.
                                      </p>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        Pourquoi utilisons-nous des cookies ?
                                      </h4>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        Cookies Nécessaires :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Ces cookies sont essentiels pour le
                                        fonctionnement technique de notre site.
                                        Ils permettent des fonctions de base
                                        comme la navigation de page et l'accès
                                        aux zones sécurisées du site. Le site
                                        web ne peut pas fonctionner correctement
                                        sans ces cookies, c’est pourquoi ils ne
                                        peuvent pas être désactivés.
                                      </p>

                                      <h4 className="mt-1 text-base font-bold text-left dark:text-white font-display">
                                        Cookies Analytiques :
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Ces cookies nous permettent de collecter
                                        des informations sur la manière dont
                                        vous utilisez notre site, y compris les
                                        pages les plus visitées et les messages
                                        d'erreur que vous pourriez recevoir. Les
                                        informations recueillies par ces cookies
                                        sont agrégées, ce qui signifie qu'elles
                                        sont anonymes. Elles sont utilisées
                                        uniquement pour améliorer la performance
                                        et la conception de notre site.
                                      </p>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        Comment gérez-vous vos préférences en
                                        matière de cookies ?
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Vous avez le contrôle total sur vos
                                        préférences de cookies. Ci-dessous, vous
                                        pouvez choisir d'activer ou de
                                        désactiver les cookies analytiques selon
                                        vos préférences. Les cookies
                                        nécessaires, quant à eux, resteront
                                        toujours actifs pour assurer le bon
                                        fonctionnement du site.
                                      </p>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        Consentement aux cookies
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Lorsque vous visitez notre site pour la
                                        première fois, nous vous demandons de
                                        configurer vos préférences en matière de
                                        cookies. Vous avez la possibilité de
                                        modifier ces préférences à tout moment
                                        en revenant sur cette page. En
                                        choisissant d'accepter tous les cookies,
                                        vous nous permettez d'utiliser des
                                        cookies analytiques pour collecter des
                                        informations sur votre interaction avec
                                        notre site. Ces informations nous sont
                                        vitales pour améliorer et personnaliser
                                        votre expérience sur Doctraitant.
                                      </p>

                                      <h4 className="mt-3 text-base font-bold text-left dark:text-white font-display">
                                        Impact des cookies sur votre expérience
                                      </h4>
                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Refuser certains types de cookies peut
                                        impacter votre expérience sur notre site
                                        et les services que nous sommes en
                                        mesure de vous offrir. Cependant, vous
                                        avez toujours le choix de personnaliser
                                        votre consentement aux cookies pour
                                        refléter vos préférences en matière de
                                        confidentialité.
                                      </p>

                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Actions possibles :
                                      </p>
                                      <ul className="m-4 space-y-3 text-sm text-gray-800 list-disc list-outside ps-3 dark:text-gray-200">
                                        <li className="ps-1">
                                          <span className="font-bold">
                                            Accepter tous les cookies :{" "}
                                          </span>
                                          Permet d'accepter tous les cookies
                                          analytiques et nécessaires, optimisant
                                          ainsi votre expérience sur notre site.
                                        </li>
                                        <li className="ps-1">
                                          <span className="font-bold">
                                            Sauvegarder les préférences :{" "}
                                          </span>
                                          Enregistre vos préférences actuelles.
                                          Seuls les cookies nécessaires
                                          resteront actifs si vous n'activez pas
                                          les cookies analytiques.
                                        </li>
                                      </ul>

                                      <p className="m-2 text-sm text-gray-800 dark:text-gray-200">
                                        Nous respectons votre droit à la vie
                                        privée et nous nous engageons à gérer
                                        vos données de manière responsable et
                                        sécurisée. Pour plus d'informations sur
                                        comment et pourquoi nous utilisons vos
                                        informations, veuillez consulter notre{" "}
                                        <span className="text-[#1E6091]">
                                          Politique de confidentialité
                                        </span>
                                        .
                                      </p>
                                    </div>
                                  </div>
                                </>
                              );
                            case ContextualViewTypeEnum.Help:
                              return (
                                <>
                                  <div className="flex flex-col w-full space-y-2">
                                    <div className="md:col-span-3">
                                      <div className="divide-y divide-gray-200 hs-accordion-group dark:divide-gray-700">
                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Qu'est-ce que Doctraitant ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.WhatSection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem === "What" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "What"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Doctraitant est une plateforme en
                                              ligne qui facilite la recherche et
                                              la prise de rendez-vous avec un
                                              médecin traitant à proximité.
                                              Notre service est conçu pour
                                              offrir une expérience utilisateur
                                              intuitive et efficace, permettant
                                              ainsi d'accéder rapidement à des
                                              soins de santé adaptés sans les
                                              longues attentes habituelles au
                                              téléphone ou les recherches
                                              fastidieuses. En utilisant
                                              Doctraitant, vous pouvez gérer
                                              facilement votre santé et celle de
                                              vos proches grâce à notre
                                              interface simple d'utilisation.
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Pourquoi choisir un médecin
                                                traitant ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.WhySection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem === "Why" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "Why"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Avoir un médecin traitant est
                                              crucial pour une gestion efficace
                                              de votre santé. Il sert de point
                                              de contact principal pour tous vos
                                              besoins médicaux, ce qui garantit
                                              une meilleure continuité des soins
                                              et une meilleure coordination
                                              entre les différents spécialistes
                                              si nécessaire. Le médecin traitant
                                              vous connaît bien, comprend votre
                                              historique médical complet, et
                                              peut ainsi adapter les traitements
                                              à vos besoins spécifiques. De
                                              plus, en France, le médecin
                                              traitant est au cœur du parcours
                                              de soins coordonnés il est donc
                                              indispensable pour bénéficier d'un
                                              remboursement optimal de vos soins
                                              de santé par l'assurance maladie.
                                              C'est lui qui vous oriente vers
                                              des spécialistes et organise votre
                                              suivi médical, assurant ainsi une
                                              approche globale et personnalisée
                                              de votre santé.
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Comment puis-je utiliser
                                                Doctraitant pour trouver un
                                                médecin traitant ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.HowSection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem === "How" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "How"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Pour trouver un médecin traitant
                                              via l'application Doctraitant,
                                              suivez ces étapes simples :
                                            </p>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                1. Téléchargez et installez
                                                l'application :
                                              </span>{" "}
                                              Commencez par télécharger
                                              l'application Doctraitant depuis
                                              votre store habituel (App Store
                                              pour iOS ou Google Play pour
                                              Android).
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                2. Créez un compte :
                                              </span>{" "}
                                              Ouvrez l'application et
                                              inscrivez-vous en fournissant les
                                              informations nécessaires. La
                                              création de compte est rapide et
                                              vous permettra d'accéder à toutes
                                              les fonctionnalités de
                                              l'application.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                3. Soumettez votre demande :
                                              </span>{" "}
                                              Une fois votre compte activé,
                                              connectez-vous et accédez à la
                                              section pour trouver un médecin.
                                              Remplissez le formulaire de
                                              demande en indiquant votre
                                              localisation.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                4. Un médecin prend en charge
                                                votre demande :
                                              </span>{" "}
                                              Après soumission de votre demande,
                                              elle sera automatiquement
                                              transmise aux médecins
                                              correspondant à vos critères. Un
                                              médecin traitant disponible vous
                                              contactera directement via
                                              l'application pour confirmer le
                                              rendez-vous.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                5. Suivi :
                                              </span>{" "}
                                              L'application vous permet
                                              également de gérer vos
                                              rendez-vous, de recevoir des
                                              rappels et d'accéder à votre
                                              historique médical en toute
                                              sécurité.
                                            </h4>

                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              L'application Doctraitant est
                                              conçue pour simplifier la gestion
                                              de votre santé, en rendant la
                                              prise de rendez-vous rapide,
                                              efficace, et adaptée à vos besoins
                                              spécifiques.
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Comment les professionnels de
                                                santé peuvent-ils s'inscrire
                                                pour prendre en charge les
                                                demandes sur Doctraitant ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.DoctorSection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem ===
                                                  "HowDoctor" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "HowDoctor"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Les professionnels de santé
                                              intéressés par rejoindre notre
                                              réseau et prendre en charge les
                                              demandes de patients via
                                              Doctraitant doivent suivre ces
                                              étapes simples :
                                            </p>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                1. Visitez notre plateforme :
                                              </span>{" "}
                                              Commencez par accéder à notre site
                                              web dédié aux professionnels de
                                              santé. Vous trouverez toutes les
                                              informations nécessaires ainsi
                                              qu'un lien vers notre formulaire
                                              d'inscription en ligne.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                2. Créez votre compte
                                                professionnel :
                                              </span>{" "}
                                              Remplissez le formulaire
                                              d'inscription en fournissant les
                                              informations requises, telles que
                                              vos qualifications, spécialités,
                                              et coordonnées. Ce processus
                                              inclut également la vérification
                                              de vos identifiants médicaux pour
                                              garantir la qualité et la sécurité
                                              des services proposés sur
                                              Doctraitant.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                3. Configuration de votre profil
                                                :
                                              </span>{" "}
                                              Une fois votre compte validé, vous
                                              pourrez configurer votre profil,
                                              indiquer vos disponibilités, et
                                              personnaliser les informations qui
                                              seront visibles par les patients.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                4. Gérer les demandes :
                                              </span>{" "}
                                              Après la configuration de votre
                                              profil, vous aurez accès à un
                                              tableau de bord où vous pourrez
                                              voir les nouvelles demandes de
                                              patients. Vous pourrez accepter de
                                              prendre en charge ces demandes
                                              selon votre disponibilité et vos
                                              préférences.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                5. Formation et support :
                                              </span>{" "}
                                              Doctraitant offre également des
                                              sessions de formation pour vous
                                              familiariser avec notre plateforme
                                              et vous assurer de maximiser son
                                              potentiel. Notre équipe de support
                                              est également disponible pour
                                              répondre à toutes vos questions ou
                                              préoccupations.
                                            </h4>

                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Nous sommes dédiés à offrir une
                                              expérience fluide et efficace tant
                                              pour les patients que pour les
                                              professionnels de santé.
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Que dois-je faire si je
                                                rencontre un problème technique
                                                avec l'application Doctraitant ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.SupportSection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem === "Support" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "Support"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Si vous rencontrez un problème
                                              technique avec l'application
                                              Doctraitant, vous avez deux
                                              options pour obtenir de l'aide
                                              rapidement :
                                            </p>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                1. Signaler le problème via
                                                votre compte utilisateur :
                                              </span>{" "}
                                              Connectez-vous à votre compte sur
                                              l'application Doctraitant. Dans le
                                              menu des paramètres ou d'aide,
                                              vous trouverez une option pour
                                              "Signaler un problème". Vous
                                              pourrez y décrire le problème
                                              rencontré et soumettre directement
                                              votre requête. Notre équipe
                                              technique recevra votre
                                              signalement et vous contactera
                                              pour résoudre le problème dans les
                                              plus brefs délais.
                                            </h4>
                                            <h4 className="mt-3 text-sm text-left dark:text-white font-display">
                                              <span className="font-semibold">
                                                2. Envoyer un email à notre
                                                support :
                                              </span>{" "}
                                              Si vous préférez, vous pouvez
                                              également envoyer un email
                                              directement à notre équipe de
                                              support technique à l'adresse{" "}
                                              <span className="text-[#1E6091]">
                                                support@doctraitant.fr
                                              </span>{" "}
                                              Veuillez inclure une description
                                              détaillée du problème, ainsi que
                                              tout message d'erreur ou capture
                                              d'écran pertinent, afin que nous
                                              puissions vous assister de manière
                                              plus efficace.
                                            </h4>

                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Nous nous efforçons de répondre à
                                              toutes les demandes rapidement et
                                              de résoudre les problèmes pour
                                              améliorer votre expérience avec
                                              Doctraitant.
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pb-6 hs-accordion">
                                          <div className="w-full pt-4 rounded-md">
                                            <div className="flex items-center justify-between mt-1 text-xs font-normal text-left text-gray-600">
                                              <h3 className="flex items-center gap-x-2 font-semibold text-base text-[#1E6091]/90">
                                                Que dois-je faire si j'ai
                                                d'autres questions qui ne sont
                                                pas adressées dans la FAQ ?
                                              </h3>
                                              <div className="inline-flex gap-x-2">
                                                <p
                                                  onClick={() =>
                                                    enableAccordion(
                                                      HelpQuestionsSectionsEnum.ContactSection
                                                    )
                                                  }
                                                  className="inline-flex items-center px-2 py-2 text-sm font-medium cursor-pointer text-sky-700"
                                                >
                                                  {activeItem === "Contact" ? (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M5 15L10 9.84985C10.2563 9.57616 10.566 9.35814 10.9101 9.20898C11.2541 9.05983 11.625 8.98291 12 8.98291C12.375 8.98291 12.7459 9.05983 13.0899 9.20898C13.434 9.35814 13.7437 9.57616 14 9.84985L19 15"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      fill="none"
                                                      className="w-6 h-6 mr-3"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g
                                                        id="SVGRepo_bgCarrier"
                                                        strokeWidth="0"
                                                      ></g>
                                                      <g
                                                        id="SVGRepo_tracerCarrier"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></g>
                                                      <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <path
                                                          d="M19 9L14 14.1599C13.7429 14.4323 13.4329 14.6493 13.089 14.7976C12.7451 14.9459 12.3745 15.0225 12 15.0225C11.6255 15.0225 11.2549 14.9459 10.9109 14.7976C10.567 14.6493 10.2571 14.4323 10 14.1599L5 9"
                                                          stroke="#1E6091"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        ></path>{" "}
                                                      </g>
                                                    </svg>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className={`hs-accordion-content w-full overflow-hidden transition-all ${
                                              activeItem === "Contact"
                                                ? "max-h-[500px] duration-500"
                                                : "max-h-[0px] duration-500"
                                            }`}
                                          >
                                            <p className="mt-4 text-sm font-light text-gray-600 dark:text-gray-400 text-start">
                                              Si vous avez des questions qui
                                              n'ont pas été traitées dans notre
                                              FAQ, n'hésitez pas à nous
                                              contacter directement. Vous pouvez
                                              envoyer votre question par email à
                                              notre équipe de support à
                                              l'adresse suivante :{" "}
                                              <span className="text-[#1E6091]">
                                                contact@doctraitant.fr
                                              </span>{" "}
                                              Nous nous engageons à répondre à
                                              toutes vos interrogations dans les
                                              meilleurs délais pour vous assurer
                                              une expérience utilisateur
                                              optimale. Votre satisfaction est
                                              notre priorité, et nous sommes là
                                              pour vous aider à naviguer sur
                                              notre plateforme et à utiliser nos
                                              services de manière efficace.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            default:
                              return <p>N/A</p>;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomContentDisplayer>
        </div>
      </div>
    </>
  );
};
