import React from "react";
import strings from "../../assets/strings";
import ManageCookies from "../layouts/ManageCookies";
import { useCookiePreferences } from "../../contexts/CookiePreferencesContext";

interface FooterProps {
  onFooterClick: (id: string) => void;
}

export const Footer: React.FC<FooterProps> = ({ onFooterClick }) => {
  const { preferences } = useCookiePreferences();
  console.log(preferences);

  return (
    <div className="max-w-6xl px-4 mx-auto divide-y divide-gray-200 xl:max-w-6xl sm:px-6 md:px-8">
      <div className="flex flex-col-reverse justify-between pt-5 pb-4 mt-4 bg-top border-t border-gray-300 lg:flex-row">
        <footer className="w-full max-w-[85rem] py-4 px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="text-center">
            <div className="flex items-center justify-center">
              <ul className="flex flex-col flex-wrap justify-center space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row sm:justify-center">
                <li>
                  <a
                    onClick={() => onFooterClick("legalMentions")}
                    className="text-base font-normal text-gray-600 transition-colors duration-300 cursor-pointer hover:text-deep-purple-accent-400"
                  >
                    {strings.legal_mentions_label}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onFooterClick("confidentialite")}
                    className="text-base font-normal text-gray-600 transition-colors duration-300 cursor-pointer hover:text-deep-purple-accent-400"
                  >
                    {strings.data_protection_label}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => onFooterClick("cookies")}
                    className="text-base font-normal text-gray-600 transition-colors duration-300 cursor-pointer hover:text-deep-purple-accent-400"
                  >
                    {strings.cookies_policies_label}
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contact@doctraitant.fr"
                    className="text-base font-normal text-gray-600 transition-colors duration-300 cursor-pointer hover:text-deep-purple-accent-400"
                  >
                    {strings.contactus_label}
                  </a>
                </li>
                <li>
                  <ManageCookies />
                </li>
              </ul>
            </div>
            <div className="mt-3 space-x-2">
              <a
                className="inline-flex items-center justify-center text-center text-gray-500 transition rounded-full size-10 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800"
                href="#"
              >
                <svg
                  className="flex-shrink-0 size-3.5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
