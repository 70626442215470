import { createContext, useState, useEffect, useContext } from "react";

import {
  type CookiePreferences,
  getCookiePreferences,
  setCookiePreferences,
} from "../utils/CookieManager";

interface CookiePreferencesContextType {
  preferences: CookiePreferences;
  setPreferences: (prefs: CookiePreferences) => void;
  showBanner: boolean;
  setShowBanner: (show: boolean) => void;
}

export const useCookiePreferences = () => {
  const context = useContext(CookiePreferencesContext);

  if (context === undefined) {
    throw new Error(
      "useCookiePreferences must be used withing a CookiePreferencesProvider"
    );
  }

  return context;
};

const CookiePreferencesContext = createContext<
  CookiePreferencesContextType | undefined
>(undefined);

export const CookiePreferencesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [preferences, setPreferences] = useState<CookiePreferences>(
    getCookiePreferences()
  );
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const storedPreferences = getCookiePreferences();

    if (storedPreferences) {
      setPreferences(storedPreferences);
      if (storedPreferences.userMadeChoice) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, []);

  const updatePreferences = (newPreferences: CookiePreferences) => {
    setPreferences(newPreferences);
    setCookiePreferences(newPreferences);
    setShowBanner(false);
  };

  const value = {
    preferences,
    setPreferences: updatePreferences,
    showBanner,
    setShowBanner,
  };

  return (
    <CookiePreferencesContext.Provider value={value}>
      {children}
    </CookiePreferencesContext.Provider>
  );
};
