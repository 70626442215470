import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export interface CookiePreferences {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
  userMadeChoice: boolean;
}

const COOKIE_NAME = "doctraitant_cookie_preferences";
const SECRET_KEY =
  "FJ_jZo]p|owzlrW0&(!uj/|2yq+SDGF$l(l[e,o<&rx{p:ngQ2>J0hv707Dn%Iw-')";

// Encrypt data
const encrypt = (data: string): string => {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

// Decrypt data
const decrypt = (text: string): string => {
  const bytes = CryptoJS.AES.decrypt(text, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Get cookie preferences
export const getCookiePreferences = (): CookiePreferences => {
  const encryptedPreferences = Cookies.get(COOKIE_NAME);

  if (encryptedPreferences) {
    try {
      const decryptedPreferences = decrypt(encryptedPreferences);
      return JSON.parse(decryptedPreferences) as CookiePreferences;
    } catch (error) {
      console.error("Error decrypting cookie:", error);
    }
  }

  return {
    necessary: true,
    analytics: false,
    marketing: false,
    userMadeChoice: false,
  };
};

// Set cookie preferences
export const setCookiePreferences = (preferences: CookiePreferences) => {
  const encryptedPreferences = encrypt(JSON.stringify(preferences));

  Cookies.set(COOKIE_NAME, encryptedPreferences, {
    expires: 365,
    secure: true,
    sameSite: "lax",
  });
};

// Clear cookie
export const clearCookiePreferences = () => {
  Cookies.remove(COOKIE_NAME);
};

export const setAnalyticsCookies = () => {
  // Implement setting of analytics cookies
  console.log("Setting analytics cookies");
};

export const removeAnalyticsCookies = () => {
  // Implement setting to remove analytics cookies
  console.log("Setting to remove analytics cookies");
};
