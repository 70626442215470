import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { SearchIcon, MapIcon } from "lucide-react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import useDebounce from "../../hooks/use-debounce";

export type Option = {
  value: string;
  label: string;
};

type LocationFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  placeholder: string;
  title: string;
  options: Option[];
  onSelect: (option: Option | null) => void;
  selectedValue: Option | null;
  locationData: Array<{ codePostal: string; nomCommune: string }>;
};

type LocationGroup = {
  postalCode: string;
  cities: Array<{ cityName: string }>;
};

export function LocationFieldModal({
  onSelect,
  locationData,
  isOpen,
  onClose,
}: LocationFieldModalProps) {
  const [inputValue, setInputValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const listRef = useRef<HTMLDivElement>(null);

  const groupedOptions = useMemo(() => {
    const normalizedInput = debouncedInputValue.trim().toLowerCase();
    const filtered = debouncedInputValue
      ? locationData.filter((location) => {
          const postalMatch = location.codePostal.includes(debouncedInputValue);
          const communeMatch = location.nomCommune
            .toLowerCase()
            .includes(normalizedInput);
          return postalMatch || communeMatch;
        })
      : locationData;

    const groups = filtered.reduce(
      (acc: { [key: string]: LocationGroup }, curr) => {
        if (!acc[curr.codePostal]) {
          acc[curr.codePostal] = {
            postalCode: curr.codePostal,
            cities: [],
          };
        }

        if (
          !acc[curr.codePostal].cities.some(
            (city) => city.cityName === curr.nomCommune
          )
        ) {
          acc[curr.codePostal].cities.push({
            cityName: curr.nomCommune,
          });
        }

        return acc;
      },
      {}
    );

    return Object.values(groups).slice(0, 4);
  }, [locationData, debouncedInputValue]);

  const flatOptions = useMemo(() => {
    return groupedOptions.flatMap((group) =>
      group.cities.map((city) => ({
        postalCode: group.postalCode,
        cityName: city.cityName,
      }))
    );
  }, [groupedOptions]);

  const handleSelect = useCallback(
    (postalCode: string, cityName: string) => {
      try {
        onSelect({
          value: postalCode,
          label: cityName,
        });
        setInputValue("");
        onClose();
      } catch (error) {
        console.error("Error in selecting city or postal code", error);
      }
    },
    [onSelect, onClose]
  );

  // Keyboard navigation
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setActiveIndex((prev) =>
            prev < flatOptions.length - 1 ? Math.max(prev + 1, 0) : prev
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setActiveIndex((prev) => (prev > 0 ? prev - 1 : -1));
          break;
        case "Enter":
          e.preventDefault();
          if (flatOptions.length > 0 && activeIndex >= 0) {
            const selected = flatOptions[activeIndex];
            handleSelect(selected.postalCode, selected.cityName);
          }
          break;
        case "Escape":
          e.preventDefault();
          onClose();
          break;
      }
    },
    [flatOptions, activeIndex, handleSelect, onClose]
  );

  useEffect(() => {
    setActiveIndex(-1);
  }, [debouncedInputValue]);

  // Scroll active option into view
  useEffect(() => {
    if (listRef.current) {
      const activeElement = listRef.current.querySelector(
        `[data-index="${activeIndex}"]`
      );

      if (activeElement) {
        activeElement.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    if (isOpen) {
      setInputValue("");
      setActiveIndex(-1);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="max-w-[calc(100vw-2rem)] md:max-w-md bg-white rounded-lg"
        onKeyDown={handleKeyDown}
      >
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-[#12CBC4] flex items-center gap-2 flex-wrap">
            <span className="inline-block p-1 rounded-md bg-[#12CBC4]/15">
              <MapIcon className="size-5" />
            </span>
            <span>Sélectionner votre localisation</span>
          </DialogTitle>
          <p className="text-sm font-normal text-gray-600">
            Indiquez votre lieu à partir du code postal ou de la commune.
          </p>
          <div className="relative">
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Lieu (Code postal ou Ville)"
              className="py-2 pl-10 border-gray-300 focus:border-[#12CBC4]/80 focus:ring-[#12CBC4]/70"
              aria-label="Search location"
            />
            <SearchIcon className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2 size-4" />
          </div>
        </DialogHeader>

        <div
          id="list-wrapper"
          className="overflow-y-auto max-h-[18rem]"
          ref={listRef}
          role="listbox"
        >
          {groupedOptions.length > 0 ? (
            groupedOptions.map((group) => (
              <div
                key={group.postalCode}
                role="group"
                aria-label={`Locations with postal code ${group.postalCode}`}
              >
                {group.cities.map((city) => {
                  const flatIndex = flatOptions.findIndex(
                    (opt) =>
                      opt.postalCode === group.postalCode &&
                      opt.cityName === city.cityName
                  );
                  const isActive = flatIndex === activeIndex;

                  return (
                    <button
                      key={`${group.postalCode}-${city.cityName}`}
                      onClick={() =>
                        handleSelect(group.postalCode, city.cityName)
                      }
                      className={`w-full px-4 py-2 rounded-lg text-sm text-left focus:outline-none ${
                        isActive
                          ? "bg-[#12CBC4]/15 text-[#12CBC4]"
                          : "hover:bg-gray-100"
                      }`}
                      data-index={flatIndex}
                      role="option"
                      aria-selected={isActive}
                      tabIndex={isActive ? 0 : -1}
                    >
                      <div className="font-medium">
                        {`${group.postalCode} - ${city.cityName}`}
                      </div>
                      <div className="text-sm text-gray-500 uppercase">
                        {city.cityName}
                      </div>
                    </button>
                  );
                })}
              </div>
            ))
          ) : (
            <p className="text-lg text-center" role="alert">
              No results found.
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
