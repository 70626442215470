import React from "react";
import { DocRequestData } from "./../types/docRequestModel";
import { GeneralAppointementResponse } from "./../types/GeneralAppointementResponse";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { addDays, isSaturday, isSunday } from "date-fns";

const isWeekendOrHoliday = (date: Date) => {
  return isSaturday(date) || isSunday(date);
};

const getAvailableDates = (takenDates: Date[]): Date[] => {
  const today = new Date();
  const availableDates = [];

  let i = 0;
  while (availableDates.length < 30) {
    const nextDate = addDays(today, i);

    // Exclude weekends and holidays
    if (isWeekendOrHoliday(nextDate) || takenDates.includes(nextDate)) {
      i++;
      continue;
    }

    // Check if the date is between 9h30 and 16h30
    const startTime = new Date(nextDate);
    startTime.setHours(9, 30, 0, 0); // Define the starting date at 9h30

    const endTime = new Date(nextDate);
    endTime.setHours(16, 30, 0, 0); // Define the end date at 16h30

    if (today <= endTime) {
      availableDates.push(today);
    }
    i++;
  }

  return availableDates;
};

const getNextDates = (excludedDates: Date[]): Date[] => {
  const today = new Date();
  const currentDate = new Date();

  const thirtyDaysFromNow = new Date(today);
  thirtyDaysFromNow.setDate(today.getDate() + 30);

  const dateArray: Date[] = [];

  while (today <= thirtyDaysFromNow) {
    if (today.getDay() !== 0 && today.getDay() !== 6) {
      // Exclude weekends
      for (let hour = 9; hour <= 16; hour++) {
        for (let minute = 30; minute <= 30; minute += 30) {
          const dateWithTime = new Date(today);
          dateWithTime.setHours(hour, minute, 0, 0);
          dateArray.push(dateWithTime);
        }
      }
    }
    today.setDate(today.getDate() + 1);
  }

  // Filtrer excluded dates
  const filteredDates = dateArray.filter(
    (date) =>
      !excludedDates.some((excludedDate) =>
        isTimeSlotCollision(date, excludedDate)
      )
  );

  // Filter the next dates
  var nextDatesToKeep = filteredDates.filter(function (date) {
    return date > currentDate;
  });

  return getFirstThreeOrAll(nextDatesToKeep);
};

const getFirstThreeOrAll = <T>(arr: T[]): T[] => {
  return arr.length >= 3 ? arr.slice(0, 3) : arr.slice();
};

const isTimeSlotCollision = (dateA: Date, dateB: Date) => {
  const endA = new Date(dateA);
  endA.setMinutes(dateA.getMinutes() + 30);

  const endB = new Date(dateB);
  endB.setMinutes(dateB.getMinutes() + 30);

  return (
    (dateA >= dateB && dateA < endB) ||
    (endA > dateB && endA <= endB) ||
    (dateB >= dateA && dateB < endA) ||
    (endB > dateA && endB <= endA)
  );
};

export const convertToISO8601Date = (chaine: Date): string => {
  // Convertir la chaine de date au format "15/01/2024 11:30:00" en objet Date
  const dateObject = new Date(chaine);

  // Extraire les composants de la date
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  // Construire la nouvelle chaîne de date au format "15-01-2024T11:30"
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
};

export const getFormalTimeWithHoursAndMinutes = (dateString: string) => {
  const date = new Date(dateString);
  const heures = date.getHours();
  const minutes = date.getMinutes();

  const heureMinutes = `${heures}h${minutes < 10 ? "0" : ""}${minutes}`;

  return heureMinutes;
};

export const getFormalDateWithDayNumberMonthAndYear = (dateString: string) => {
  const date = new Date(dateString);
  const joursSemaine = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const mois = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const jourSemaine = joursSemaine[date.getDay()];
  const jour = date.getDate();
  const moisAnnee = mois[date.getMonth()];
  const annee = date.getFullYear();
  const heures = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return `${jourSemaine} ${jour} ${moisAnnee} ${annee}`;
};

export const getFormalDateWithDayAsNumberShortcutMonthAndYear = (
  dateString: string
) => {
  const date = new Date(dateString);
  const jour = date.getDate();
  const mois = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];
  const moisAnnee = mois[date.getMonth()];
  const annee = date.getFullYear();

  return `${jour} ${moisAnnee} ${annee}`;
};

/*
 * Get the closest availabe dates from calendar
 */
export const getTheClosestAvailableDates = async () => {
  try {
    const response = await fetch(
      `https://api.doctraitant.fr/api/appointment/filtred`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          meetingDateTime: { $ne: null },
        }),
      }
    );

    if (response.ok) {
      const datao: GeneralAppointementResponse = await response.json();
      const takenDates = datao.results.data.map(
        (appointment) => new Date(appointment.meetingDateTime ?? "")
      );
      return getNextDates(takenDates);
    } else {
      console.error("Échec de la mise à jour de l'e-mail");
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'e-mail :", error);
  }
};

export function dateformatter(dateString: string) {
  if (!dateString || dateString.trim() === "") {
    return "Date invalide";
  }

  const components = dateString.split(/[\/\s:]+/);

  if (components.length < 6) {
    return "Format de date invalide";
  }

  const [jour, mois, annee, heure, minutes, secondes] = components;
  const date = new Date(
    +annee,
    +mois - 1,
    +jour,
    +heure,
    +minutes,
    +secondes || 0
  );

  const dateFormatee = format(date, "EEEE d MMMM yyyy 'à' HH'h'mm", {
    locale: fr,
  });

  return dateFormatee;
}

export function calculateAge(birthDateString: string | Date) {
  const birthDate = new Date(birthDateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

// Utility function to format the date
export function formatDate(isoDateString: string): string {
  const date = new Date(isoDateString);

  // Create an Intl.DateTimeFormat instance with the desired locale and options
  const formatter = new Intl.DateTimeFormat("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false, // Use 24-hour time format
  });

  // Format the date
  return formatter.format(date);
}
