// FloatingLabelInputField.tsx
import { cn } from "../../lib/utils";
import React from "react";

interface FloatingLabelInputFieldProps {
  id: string;
  type: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string | React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

const FloatingLabelInputField: React.FC<FloatingLabelInputFieldProps> = ({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  label,
  disabled = false,
  readOnly = false,
  className,
}) => {
  return (
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        className={cn(
          "peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-90 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2",
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      <label
        htmlFor={id}
        className="text-gray-600 absolute top-0 left-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-90 peer-disabled:pointer-events-none
                peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-gray-500 
                peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-gray-500"
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInputField;
