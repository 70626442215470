import React, { useState, useEffect, useMemo } from "react";
import strings from "../../assets/strings";
import qrCode from "./../../images/qr_code_ic.png";
import appLogo from "./../../images/app_logo.png";
import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "./../layouts/FloatingLabelInput";
import FloatingDateInput from "./../layouts/FloatingDateInput";
import FloatingSelect from "./../layouts/FloatingSelect";
import Modal from "./../layouts/Modal";
import Toast from "./../layouts/Toast";
import LocationData from "../../assets/raw/france_locations.json";
import { LocationFieldModal } from "./../layouts/LocationFieldModal";

// Utils imports
import { getTheRightRequestType } from "./../../utils/AppointementRequestDecode";
import {
  getTheClosestAvailableDates,
  dateformatter,
  convertToISO8601Date,
  getFormalDateWithDayAsNumberShortcutMonthAndYear,
  calculateAge,
} from "./../../utils/IAHelperForDate";

// Icons imports
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import TaskIcon from "@mui/icons-material/Task";
import { arePinFieldsValid, isValidEmail } from "../../utils/DataChecker";
import FloatingLabelSelectInputField from "../Inputs/FloatingLabelSelectInputField";
import FloatingLabelInputField from "../Inputs/FloatingLabelInputField";
import AppointmentRepository from "../../Domain/Appointments/AppointmentRepository";
import ApiService from "../../services/ApiService";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { CalendarIcon } from "lucide-react";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import { format } from "date-fns";
import { Calendar } from "../ui/calendar";

type Option = {
  value: string;
  label: string;
};

export const EasyAccessView = () => {
  const apiUrl = "https://api.doctraitant.fr/api";
  const apiService = new ApiService(apiUrl);

  const appointmentRepository = new AppointmentRepository(
    localStorage.getItem("userId") ?? "",
    apiService
  );

  const [isActionsDisabled, setIsActionsDisabled] = useState(false);
  const [isFormNotReady, setIsFormNotReady] = useState(true);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalIcon, setModalIcon] = useState(<TaskIcon />);
  const [modalTitle, setModalTitle] = useState("");
  const [modalColor, setModalColor] = useState("#12CBC4");
  const [modalButtonText, setModalButtonText] = useState("");
  const [modalButtonColorClass, setModalButtonColorClass] =
    useState("bg-[#12CBC4]");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [modalAction, setModalAction] = useState(() => () => {});
  const [openModalFor, setOpenModalFor] = useState<
    "city" | "postalCode" | null
  >(null);
  const navigate = useNavigate();

  const [firstnameValue, setFirstnameValue] = useState<string>("");
  const [lastnameValue, setLastnameValue] = useState<string>("");
  const [birthdateValue, setBirthdateValue] = useState<Date>();
  const [genderValue, setGenderValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [adresseValue, setAdresseValue] = useState<string>("N/A");
  const [cityValue, setCityValue] = useState<string>("");
  const [townshipValue, setTownshipValue] = useState<string>("N/A");
  const [zipcodeValue, setZipcodeValue] = useState<string>("");
  const [pinAuthValue, setPinAuthValue] = useState<string>("");
  const [pinAuthConfirmationValue, setPinAuthConfirmationValue] =
    useState<string>("");
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedPostalCode, setSelectedPostalCode] = useState<Option | null>(
    null
  );

  const [aldChecked, setAldChecked] = useState(false);
  const [handicapChecked, setHandicapChecked] = useState(false);
  const [pregnantChecked, setPregnantChecked] = useState(false);
  const [canMoveChecked, setCanMoveChecked] = useState(false);

  const handleAldChange = () => setAldChecked(!aldChecked);
  const handleHandicapChange = () => setHandicapChecked(!handicapChecked);
  const handlePregnantChange = () => setPregnantChecked(!pregnantChecked);
  const handleCanMoveChange = () => setCanMoveChecked(!canMoveChecked);

  const resetZoom = () => {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      // Désactive temporairement le zoom
      viewportMeta.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      );
      // Rétablit les paramètres par défaut après un court délai
      setTimeout(() => {
        viewportMeta.setAttribute("content", "width=device-width, initial-scale=1.0");
      }, 300);
    }
  };

  const handleFirstnameChange = (value: string) => {
    setFirstnameValue(value);
  };
  const handleFirstnameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstnameValue(event.target.value);
  };

  const handleLastnameChange = (value: string) => {
    setLastnameValue(value);
  };

  const handleLastnameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastnameValue(event.target.value);
  };

  const handleBirthdateChange = (value: Date) => {
    setBirthdateValue(value);
  };

  const handleEmailChange = (value: string) => {
    setEmailValue(value);
  };
  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailValue(event.target.value);
  };

  const handlePhoneChange = (value: string) => {
    setPhoneValue(value);
  };
  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneValue(event.target.value);
  };

  const handleAdresseChange = (value: string) => {
    setAdresseValue(value);
  };
  const handleAdresseInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdresseValue(event.target.value);
  };

  const handleCityChange = (value: string) => {
    setCityValue(value);
  };
  const handleCityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCityValue(event.target.value);
  };

  const handleTownshipChange = (value: string) => {
    setTownshipValue(value);
  };
  const handleTownshipInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTownshipValue(event.target.value);
  };

  const handleZipcodeChange = (value: string) => {
    setZipcodeValue(value);
  };
  const handleZipcodeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setZipcodeValue(event.target.value);
  };

  const handlePinAuthChange = (value: string) => {
    setPinAuthValue(value);
  };

  const handlePinAuthInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPinAuthValue(event.target.value);
  };

  const handlePinAuthConfirmationChange = (value: string) => {
    setPinAuthConfirmationValue(value);
  };
  const handlePinAuthConfirmationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPinAuthConfirmationValue(event.target.value);
  };

  const handleGenderChange = (value: string) => {
    setGenderValue(value);
  };
  const handleGenderInputChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setGenderValue(event.target.value);
  };

  const postalCodeOptions = useMemo(() => {
    return LocationData.map((location) => ({
      value: location.codePostal,
      label: location.codePostal,
    }));
  }, []);

  const cityOptions = useMemo(() => {
    return Array.from(
      new Set(LocationData.map((location) => location.nomCommune))
    ).map((commune) => ({
      value: commune,
      label: commune,
    }));
  }, []);

  const handleLocationSelectChange = (selected: Option | null) => {
    if (!selected) return;
    resetZoom();
    const postalCode = selected.value;
    const cityName = selected.label;

    const selectedLocation = LocationData.find(
      (loc) => loc.codePostal === postalCode && loc.nomCommune === cityName
    );

    if (selectedLocation) {
      setSelectedPostalCode({
        value: selectedLocation.codePostal,
        label: selectedLocation.codePostal,
      });
      setSelectedCity({
        value: selectedLocation.nomCommune,
        label: selectedLocation.nomCommune,
      });
      setZipcodeValue(selectedLocation.codePostal);
      setCityValue(selectedLocation.nomCommune);
    }
  };

  const options = [
    { value: "", label: "Sélectionnez votre sexe" },
    { value: "F", label: "Féminin" },
    { value: "M", label: "Masculin" },
    { value: "O", label: "Autre" },
  ];

  function handleNavigationToSignin() {
    // Handle navigation to signin from registration page
    // Then trigger navigation.
    navigate("/", { replace: true });
  }

  // Toast
  const [toastType, setToastType] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToast, setShowToast] = useState(false);

  const handleShowToast = (
    type: "success" | "warning" | "error",
    message: string
  ) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      setToastType("");
      setToastMessage("");
      setIsActionsDisabled(false);
    }, 2000);
  };

  // Close calendar on day click
  const handleCloseCalendarOnDayClick = () => {
    if (isCalendarOpen) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.title = strings.new_account_title_tab;
  }, []);

  useEffect(() => {
    const requestBody = {
      firstname: firstnameValue,
      lastname: lastnameValue,
      gender: genderValue,
      address: adresseValue,
      zipcode: zipcodeValue,
      phone: phoneValue,
      email: emailValue,
      birthdate: birthdateValue,
      city: cityValue,
      township: townshipValue,
    };

    // Check if there is an empty field
    const isAnyFieldEmpty = Object.values(requestBody).some(
      (value) => value === "" || value === null || value === undefined
    );

    // if there is an empty field or pins are differents, block register button
    if (isAnyFieldEmpty || !isValidEmail(requestBody.email)) {
      setIsFormNotReady(true);
    } else {
      setIsFormNotReady(false);
    }
  }, [
    firstnameValue,
    lastnameValue,
    birthdateValue,
    genderValue,
    phoneValue,
    emailValue,
    adresseValue,
    cityValue,
    townshipValue,
    zipcodeValue,
  ]);

  const askForOnlineAppointement = async () => {
    setIsActionsDisabled(true);
    setIsFormNotReady(true);
    try {
      const data = {
        requestType: "MT",
        requestState: "En cours",
        priority: "Faible",
        userId: "",
        isPregnant: false,
        isAld: aldChecked,
        isHandicapped: handicapChecked,
        isMobile: canMoveChecked,
        firstname: firstnameValue,
        lastname: lastnameValue,
        gender: genderValue,
        birthdate: birthdateValue?.toLocaleDateString("fr-FR"),
        Address: adresseValue,
        city: cityValue,
        zipcode: zipcodeValue,
        requestDescription: "",
        age: calculateAge(birthdateValue!),
        phone: phoneValue,
        email: emailValue,
      };

      console.log({ data });
      const dataToSend =
        await appointmentRepository.createOnlineAppointmentWithoutToken(data);

      setModalTitle("Confirmation");
      setModalIcon(<TaskIcon fontSize="large" />);
      setModalMessage(
        "Votre demande a bien été transmise ! Vous recevrez une réponse dans les plus brefs délais."
      );
      setModalColor("#12CBC4");
      setModalButtonText(strings.agree_label);
      setModalButtonColorClass("bg-[#12CBC4]");
      setModalAction(() => handleNavigationToSignin);
      setModalOpen(true);
    } catch (error) {
      setModalTitle("Erreur");
      setModalIcon(<ErrorIcon className="text-[#FF6B6B]" fontSize="large" />);
      setModalMessage(
        "Oups ! Une erreur est survenue lors de la soumission de votre demande. Veuillez réessayer."
      );
      setModalColor("#FF6B6B");
      setModalButtonText(strings.close_label);
      setModalButtonColorClass("bg-[#FF6B6B]");
      setModalAction(() => () => setModalOpen(false));
      setModalOpen(true);
      setIsActionsDisabled(false);
      setIsFormNotReady(false);
      console.error(
        "Erreur lors de la création de la demande en ligne :",
        error
      );
    }
  };

  const registerUser = async () => {
    setIsActionsDisabled(true);
    setIsFormNotReady(true);
    try {
      const requestBody = {
        firstname: firstnameValue,
        lastname: lastnameValue,
        userRights: "Medecin",
        gender: genderValue,
        isUserFromMobile: true,
        address: adresseValue,
        zipcode: zipcodeValue,
        phone: phoneValue,
        email: emailValue,
        birthdate: birthdateValue?.toLocaleDateString("fr-FR"),
        city: cityValue,
        township: townshipValue,
        password: pinAuthValue,
      };

      const response = await fetch(
        `https://api.doctraitant.fr/api/appointment/ask-online-appointment-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Inscription réussie");
        setModalOpen(true);
      } else {
        console.error("Échec de linscription");
        setIsActionsDisabled(false);
        setIsFormNotReady(false);
        handleShowToast("error", "Oups ! erreur lors l'inscription");
      }
    } catch (error) {
      console.log("Erreur lors de linscription :", error);
      throw error;
    }
  };

  return (
    <div className="relative flex h-svh">
      {/* City Modal */}
      <LocationFieldModal
        isOpen={openModalFor === "city"}
        onClose={() => setOpenModalFor(null)}
        options={[...postalCodeOptions, ...cityOptions]}
        placeholder="Ville"
        title="Sélectionnez la ville"
        onSelect={handleLocationSelectChange}
        selectedValue={selectedCity}
        locationData={LocationData}
      />
      {/* Postal Code Modal */}
      <LocationFieldModal
        isOpen={openModalFor === "postalCode"}
        onClose={() => setOpenModalFor(null)}
        options={[...postalCodeOptions, ...cityOptions]}
        placeholder="Code Postal"
        title="Sélectionnez le code postal"
        onSelect={handleLocationSelectChange}
        selectedValue={selectedPostalCode}
        locationData={LocationData}
      />

      {/* Partie gauche (2/3 de l'écran), avec une barre jaune fixe en haut */}
      <div className="flex flex-col w-full h-full bg-slate-50 md:w-2/3">
        {/* Barre en haut avec logo, hauteur fixe */}
        <div className="flex items-center justify-center flex-shrink-0 w-full h-20 px-8 md:justify-start">
          {/* Logo */}
          <img src={appLogo} alt="DocTraitant Logo" className="h-8" />
        </div>

        {/* Contenu scrollable avec un padding en bas */}
        <div className="flex-1 pb-16 overflow-y-auto">
          <div className="p-4">
            {/* Formulaire */}
            <form>
              <div className="space-y-4">
                <div className="">
                  <div>
                    <label className="block text-[#22a6b3] text-xs md:text-sm font-semibold mb-1">
                      <h6 className="text-xl md:text-2xl text-[#22a6b3] font-display font-semibold text-left xl:text-bold flex items-center">
                        <span>
                          <DriveFileRenameOutlineRoundedIcon
                            htmlColor="#22a6b3"
                            className="text-lg md:text-2xl" // Ajuste la taille de l'icône
                          />
                        </span>
                        {strings.create_user_request_label}
                      </h6>
                    </label>
                  </div>
                  <p className="mt-4 ml-1 text-sm leading-6 text-gray-500">
                    {strings.create_user_request_description_label}
                  </p>
                  <div className="mt-4 ml-1 text-sm font-semibold text-gray-700 font-display">
                    Vous avez déjà l'application?{" "}
                    <a
                      className="cursor-pointer text-[#12CBC4]"
                      onClick={handleNavigationToSignin}
                    >
                      {" "}
                      {strings.please_connect_to_the_app_label}
                    </a>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="grid w-full grid-cols-1 p-6 mt-10 gap-x-4 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-8">
                        <h3 className="flex items-center gap-x-2 font-medium text-base text-[#2f3542]/90">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          Informations personnelles
                        </h3>
                        <p className="mt-2 text-sm font-light text-gray-600 text-start">
                          Partagez vos détails personnels pour personnaliser
                          votre expérience et nous permettre de mieux vous
                          connaître.
                        </p>
                      </div>

                      {/* All your input fields remain the same */}

                      <div className="sm:col-span-3">
                        <FloatingLabelInputField
                          id="createFirstnameField"
                          type="text"
                          name="firstnameField"
                          placeholder="Prénom"
                          value={firstnameValue}
                          onChange={handleFirstnameInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Prénom</span>{" "}
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      <div className="sm:col-span-3">
                        <FloatingLabelInputField
                          id="readLastnameField"
                          type="text"
                          name="lastnameField"
                          placeholder="Nom de famille"
                          value={lastnameValue}
                          onChange={handleLastnameInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Nom de famille</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      <div className="sm:col-span-3">
                        <FloatingLabelSelectInputField
                          id="createGenderField"
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Genre</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          options={options}
                          onChange={handleGenderInputChange}
                          value={genderValue}
                          disabled={isActionsDisabled}
                          className="border-red-600"
                        />
                      </div>
                      <div className="pt-2 overflow-hidden text-xs bg-white rounded sm:col-span-3">
                        <label className="flex items-center px-2 text-gray-500 gap-x-1">
                          Date de naissance <b className="text-red-500">*</b>
                        </label>
                        <Popover
                          open={isCalendarOpen}
                          onOpenChange={setIsCalendarOpen}
                        >
                          <PopoverTrigger asChild disabled={isActionsDisabled}>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full min-w-[200px] hover:bg-background bg-background justify-start border-transparent h-[30px] px-2 shadow-none text-left font-normal"
                              )}
                            >
                              <CalendarIcon className="w-4 h-4 mr-2" />
                              {birthdateValue ? (
                                format(birthdateValue, "dd/MM/yyyy")
                              ) : (
                                <span>Sélectionnez une date</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={birthdateValue}
                              onSelect={(newDate: Date | undefined) => handleBirthdateChange(newDate!)}
                              onDayClick={handleCloseCalendarOnDayClick}
                              language="fr"
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </div>

                      {/* Repeat the remaining fields similarly */}

                      <div className="sm:col-span-8">
                        <h3 className="flex items-center gap-x-2 font-medium text-base text-[#2f3542]/90">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>
                          Coordonnées
                        </h3>
                        <p className="mt-2 text-sm font-light text-gray-600 text-start">
                          Fournissez vos coordonnées pour créer une connexion
                          plus étroite et faciliter la communication avec vous.
                        </p>
                      </div>
                      <div className="sm:col-span-3">
                        <FloatingLabelInputField
                          id="createEmailField"
                          type="email"
                          name="emailField"
                          placeholder="Adresse email"
                          value={emailValue}
                          onChange={handleEmailInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Adresse email</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      <div className="sm:col-span-3">
                        <FloatingLabelInputField
                          id="createPhoneField"
                          type="number"
                          name="phoneField"
                          placeholder="N° de téléphone"
                          value={phoneValue}
                          onChange={handlePhoneInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>N° de téléphone</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      {/* <div className="col-span-full">
                        <FloatingLabelInputField
                          id="createAdresseField"
                          type="text"
                          name="adresseField"
                          placeholder="Adresse"
                          value={adresseValue}
                          onChange={handleAdresseInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Adresse</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div> */}
                      <div
                        className="relative bg-white cursor-pointer sm:col-span-3"
                        onClick={() => setOpenModalFor("city")}
                      >
                        <FloatingLabelInputField
                          id="createCityField"
                          type="text"
                          name="cityField"
                          placeholder="Ville"
                          value={cityValue}
                          readOnly
                          onChange={handleCityInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Ville</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                          className="pointer-events-none"
                        />
                      </div>
                      <div
                        className="cursor-pointer sm:col-span-3"
                        onClick={() => setOpenModalFor("postalCode")}
                      >
                        <FloatingLabelInputField
                          id="createZipcodeField"
                          type="text"
                          name="zipcodeField"
                          placeholder="Code Postal"
                          value={zipcodeValue}
                          readOnly
                          onChange={handleZipcodeInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Code Postal</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                          className="pointer-events-none"
                        />
                      </div>
                      {/* <div className="sm:col-span-2 sm:col-start-1">
                        <FloatingLabelInputField
                          id="createCityField"
                          type="text"
                          name="cityField"
                          placeholder="Ville"
                          value={cityValue}
                          onChange={handleCityInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Ville</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <FloatingLabelInputField
                          id="createTownshipField"
                          type="text"
                          name="TownshipField"
                          placeholder="Commune"
                          value={townshipValue}
                          onChange={handleTownshipInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Commune</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <FloatingLabelInputField
                          id="createZipcodeField"
                          type="text"
                          name="zipcodeField"
                          placeholder="Code Postal"
                          value={zipcodeValue}
                          onChange={handleZipcodeInputChange}
                          label={
                            <div className="flex items-center gap-x-1">
                              <span>Code Postal</span>
                              <b className="text-red-500">*</b>
                            </div>
                          }
                          disabled={isActionsDisabled}
                        />
                      </div> */}
                      <div className="col-span-full">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-6 mt-1">
                            <input
                              id="hs-checkbox-ald"
                              name="hs-checkbox-ald"
                              type="checkbox"
                              className="border-gray-200 rounded focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
                              style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "#1D4D75",
                              }}
                              aria-describedby="hs-checkbox-ald-description"
                              checked={aldChecked}
                              onChange={handleAldChange}
                              disabled={isActionsDisabled}
                            />
                          </div>
                          <label htmlFor="hs-checkbox-ald" className="ms-3">
                            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300 text-start">
                              ALD (Affection de Longue Durée)
                            </span>
                            <span
                              id="hs-checkbox-ald-description"
                              className="block text-sm text-gray-600 dark:text-neutral-500 text-start"
                            >
                              Cochez cette case si vous bénéficiez d'une ALD.
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-6 mt-1">
                            <input
                              id="hs-checkbox-handicap"
                              name="hs-checkbox-handicap"
                              type="checkbox"
                              className="border-gray-200 rounded focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
                              style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "#1D4D75", // Applique la couleur personnalisée quand la case est cochée
                              }}
                              aria-describedby="hs-checkbox-handicap-description"
                              checked={handicapChecked}
                              onChange={handleHandicapChange}
                              disabled={isActionsDisabled}
                            />
                          </div>
                          <label
                            htmlFor="hs-checkbox-handicap"
                            className="ms-3"
                          >
                            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300 text-start">
                              Situation de handicap
                            </span>
                            <span
                              id="hs-checkbox-handicap-description"
                              className="block text-sm text-gray-600 dark:text-neutral-500 text-start"
                            >
                              Cochez cette case si vous êtes en situation de
                              handicap.
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-6 mt-1">
                            <input
                              id="hs-checkbox-deplacement"
                              name="hs-checkbox-deplacement"
                              type="checkbox"
                              className="border-gray-200 rounded focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
                              style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "#1D4D75",
                              }}
                              aria-describedby="hs-checkbox-deplacement-description"
                              checked={canMoveChecked}
                              onChange={handleCanMoveChange}
                              disabled={isActionsDisabled}
                            />
                          </div>
                          <label
                            htmlFor="hs-checkbox-deplacement"
                            className="ms-3"
                          >
                            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-300 text-start">
                              Capacité de déplacement
                            </span>
                            <span
                              id="hs-checkbox-deplacement-description"
                              className="block text-sm text-gray-600 dark:text-neutral-500 text-start"
                            >
                              Cochez cette case si vous pouvez vous déplacer au
                              cabinet médical.
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <button
                  type="button"
                  className="bg-[#12CBC4]/90 text-gray-100 p-4 w-full rounded-lg tracking-wide
                                      font-semibold font-display hover:bg-[#12CBC4] focus:outline-none focus:shadow-outline
                                      shadow-lg disabled:opacity-50 disabled:pointer-events-none"
                  disabled={isActionsDisabled || isFormNotReady}
                  onClick={askForOnlineAppointement}
                >
                  {strings.create_online_request_button_label}
                </button>
              </div>
            </form>
            <Modal
              open={modalOpen}
              title="Filtres"
              onClose={() => setModalOpen(false)}
              data-modal-backdrop="static"
            >
              <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                <div
                  className={`relative overflow-hidden min-h-[8rem] text-center rounded-t-xl p-2 pt-4 pl-4 pr-4`}
                  style={{ backgroundColor: modalColor }}
                >
                  <div className="flex items-center justify-between">
                    <h3 className="flex items-center font-bold text-white gap-x-2">
                      <EditIcon fontSize="medium" />
                      {modalTitle}
                    </h3>
                  </div>
                  <figure className="absolute inset-x-0 bottom-0 -mb-px">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1920 100.1"
                    >
                      <path
                        fill="currentColor"
                        className="fill-white dark:fill-gray-800"
                        d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                      ></path>
                    </svg>
                  </figure>
                </div>

                <div className="relative z-10 -mt-12">
                  <span className="mx-auto flex justify-center items-center w-[62px] h-[62px] rounded-full border border-gray-200 bg-white text-[#12CBC4] shadow-sm">
                    {modalIcon}
                  </span>
                </div>

                <div className="p-4 overflow-y-auto sm:p-7">
                  <div className="text-center">
                    <h3 className="text-base font-normal text-gray-800 dark:text-gray-200">
                      {modalMessage}
                    </h3>
                  </div>

                  <div className="mt-2 sm:mt-5">
                    <p className="text-sm font-normal text-gray-500">
                      Pour faciliter le suivi de votre demande,{" "}
                      <a
                        href="https://doctraitant.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-bold underline"
                      >
                        Télécharger
                      </a>{" "}
                      notre application mobile. Vous pourrez ainsi soumettre et
                      suivre vos demandes, et recevoir des notifications en
                      temps réel.
                    </p>
                  </div>

                  <button
                    type="button"
                    className={`mt-10 text-gray-100 p-4 w-full rounded-lg tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline shadow-lg disabled:opacity-50 disabled:pointer-events-none ${modalButtonColorClass}`}
                    onClick={modalAction}
                  >
                    <p className="text-white">{modalButtonText}</p>
                  </button>
                </div>
              </div>
            </Modal>

            {showToast && <Toast type={toastType} message={toastMessage} />}
          </div>
        </div>
      </div>

      {/* Partie droite fixe (1/3 de l'écran) */}
      <div className="hidden md:flex flex-col bg-[#0E98B7] md:w-1/3 w-full h-svh p-4 text-white items-center justify-center fixed right-0 top-0">
        {/* Titre */}
        <h1 className="mb-4 text-2xl font-bold text-center">
          Téléchargez l'application mobile pour un accès simplifié à votre
          médecin traitant!
        </h1>
        {/* Texte après le titre */}
        <p className="mb-4 text-center">
          Faites votre demande en quelques clics, suivez son évolution en temps
          réel et soyez avertis dès que c’est validé.
        </p>
        <br />
        <br />
        {/* Image centrée */}
        <div className="flex justify-center mb-4">
          <img
            src={qrCode}
            alt="Description de l'image"
            className="object-cover w-32 h-32"
          />
        </div>
        <br />
        <br />
        {/* Phrase centrée */}
        <p className="text-xl text-center indie-flower-regular">
          Prenez soin de votre santé dès maintenant!
        </p>
      </div>

      {/* Barre noire fixe en bas pour la vue mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-[#0E98B7] h-16 p-4 text-white flex items-center justify-between">
        {/* Titre rouge à gauche */}
        <p className="text-sm font-bold text-white">Obtenez l'appli mobile</p>
        {/* Bouton simple à droite */}
        <a
          href="https://doctraitant.fr/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1D4D75] px-4 py-2 rounded-lg text-white text-base font-semibold shadow-lg"
        >
          Télécharger
        </a>
      </div>
    </div>
  );
};
function setDataLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
