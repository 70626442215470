import { useState } from "react";
import { CookieIcon } from "lucide-react";

import { useCookiePreferences } from "../../contexts/CookiePreferencesContext";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";

const ManageCookies = () => {
  const { preferences, setPreferences } = useCookiePreferences();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState(preferences);

  const handleSavePreferences = () => {
    setPreferences({ ...selectedPreferences, userMadeChoice: true });
    setIsOpen(false);
  };

  const handleAnalyticsChecked = (checked: boolean) => {
    setSelectedPreferences((prev) => ({ ...prev, analytics: checked }));
  };

  const handleMarketingChecked = (checked: boolean) => {
    setSelectedPreferences((prev) => ({ ...prev, marketing: checked }));
  };

  const handleRejectAll = () => {
    setSelectedPreferences({
      necessary: true,
      analytics: false,
      marketing: false,
      userMadeChoice: true,
    });
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="text-base text-gray-600"
      >
        Gérer les cookies
      </button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="overflow-y-auto max-h-[90svh] rounded-lg sm:max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-xl text-center">
              Gérer vos préférences de cookies
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-6">
            <div className="text-center">
              <CookieIcon className="size-12 mb-4 mx-auto text-[#12cbc4]" />
              <p className="text-sm text-gray-600">
                Vous pouvez modifier vos préférences de cookies à tout moment.
                Les cookies nécessaires sont requis pour le bon fonctionnement
                du site.
              </p>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="font-semibold">Cookies nécessaires</Label>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Requis</span>
                  <Switch checked disabled />
                </div>
              </div>

              <div className="space-y-2">
                <Label className="font-semibold">Analytique</Label>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Optionnel</span>
                  <Switch
                    checked={selectedPreferences.analytics}
                    onCheckedChange={handleAnalyticsChecked}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label className="font-semibold">Commercialisation</Label>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Optionnel</span>
                  <Switch
                    checked={selectedPreferences.marketing}
                    onCheckedChange={handleMarketingChecked}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Button
                onClick={handleRejectAll}
                variant="outline"
                className="bg-[#12CBC4] rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 w-full"
              >
                Refuser tout
              </Button>
              <Button
                onClick={handleSavePreferences}
                className="bg-[#12CBC4] rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 w-full"
              >
                Sauvegarder les préférences
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManageCookies;
