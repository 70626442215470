import { useState } from "react";
import { CookieIcon } from "lucide-react";

import { useCookiePreferences } from "../../contexts/CookiePreferencesContext";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";

export const CookieConsent = () => {
  const { preferences, setPreferences } = useCookiePreferences();
  const [showCustomize, setShowCustomize] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState(preferences);

  const handleAcceptAll = () => {
    setPreferences({
      necessary: true,
      analytics: true,
      marketing: true,
      userMadeChoice: true,
    });
  };

  const handleRejectAll = () => {
    setPreferences({
      necessary: true,
      analytics: false,
      marketing: false,
      userMadeChoice: true,
    });
  };

  const handleSavePreferences = () => {
    setPreferences({ ...selectedPreferences, userMadeChoice: true });
    setShowCustomize(false);
  };

  const handleAnalayticsChecked = (checked: boolean) => {
    setSelectedPreferences((prev) => ({ ...prev, analytics: checked }));
  };

  const handleMarektingChecked = (checked: boolean) => {
    setSelectedPreferences((prev) => ({ ...prev, marketing: checked }));
  };

  return (
    <Dialog open={!preferences.userMadeChoice} onOpenChange={() => {}}>
      <DialogContent className="overflow-y-auto max-h-[90svh] rounded-lg sm:max-w-xl">
        <DialogHeader>
          <DialogTitle className="text-xl text-center">
            Vos préférences et votre vie privée avant tout.
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          <div className="text-center">
            <CookieIcon className="size-8 sm:size-12 mb-4 mx-auto text-[#12cbc4]" />
            <div>
              <p className="text-sm text-gray-600">
                Chez Doctraitant, nous utilisons des cookies et des données
                anonymisées pour assurer une expérience optimale, comprendre
                comment notre application est utilisée et adapter les contenus à
                vos besoins. Vous êtes libre de choisir comment vos données sont
                gérées.
              </p>
              <br />
              <p className="text-sm text-gray-600">
                Pour plus de détails sur l’utilisation des cookies et notre
                engagement en matière de confidentialité, consultez notre
                politique dédiée.
              </p>
            </div>
          </div>

          {!showCustomize ? (
            <div className="flex flex-col items-center gap-2 md:flex-row md:justify-center">
              <Button
                onClick={() => setShowCustomize(true)}
                variant="ghost"
                className="text-[#12CBC4] w-full md:w-auto bg-gray-50 hover:bg-gray-50 h-10 rounded-lg hover:text-[#12CBC4] min-w-28"
              >
                En savoir plus
              </Button>
              <Button
                onClick={handleRejectAll}
                variant="outline"
                className="bg-[#12CBC4] w-full md:w-auto rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 min-w-28"
              >
                Refuser
              </Button>
              <Button
                onClick={handleAcceptAll}
                className="bg-[#12CBC4] w-full md:w-auto rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 min-w-28"
              >
                Accepter
              </Button>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label className="font-semibold">Cookies nécessaires</Label>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">Requis</span>
                    <Switch checked disabled />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label className="font-semibold">Analytique</Label>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">Optionnel</span>
                    <Switch
                      checked={
                        preferences.analytics || selectedPreferences.analytics
                      }
                      onCheckedChange={handleAnalayticsChecked}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label className="font-semibold">Commercialisation</Label>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">Optionnel</span>
                    <Switch
                      checked={
                        preferences.marketing || selectedPreferences.marketing
                      }
                      onCheckedChange={handleMarektingChecked}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Button
                  onClick={handleRejectAll}
                  variant="outline"
                  className="bg-[#12CBC4] rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 w-full"
                >
                  Refuser tout
                </Button>
                <Button
                  onClick={handleSavePreferences}
                  className="bg-[#12CBC4] rounded-lg transition-colors duration-200 text-gray-100 hover:bg-[#12CBC4]/90 hover:text-gray-100 h-10 w-full"
                >
                  Accepter la sélection
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
