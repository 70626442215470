import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import { fr } from "date-fns/locale";

import { isAfter } from "date-fns";

import { cn } from "../../lib/utils";
import { buttonVariants } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "../ui/select";

// Extend the DayPicker component to accept three new props & omit two existing props
export type CalendarProps = Omit<
  React.ComponentProps<typeof DayPicker>,
  "selected" | "onSelect"
> & {
  language?: "en" | "fr";
  selected?: Date;
  onSelect?: (newDate: Date | undefined) => void; // Accept undefined
};

export function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  language = "en", // defaults to English
  selected,
  onSelect,
  ...props
}: CalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState<Date>(
    selected instanceof Date ? selected : new Date()
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    selected instanceof Date ? selected : undefined
  );

  React.useEffect(() => {
    if (selected instanceof Date) {
      setCurrentMonth(selected);
      setSelectedDate(selected);
    }
  }, [selected]);

  // Generate years starting 130 years ago up to the current year
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 130 }, (_, i) => currentYear - i);
  };

  const handleMonthChange = (month: number) => {
    const updatedDate = new Date(
      currentMonth.getFullYear(),
      month,
      currentMonth.getDate()
    );
    setCurrentMonth(updatedDate);
    setSelectedDate(undefined); // Clear the selected day
    onSelect && onSelect(undefined); // Notify parent
  };

  const handleYearChange = (year: number) => {
    const updatedDate = new Date(
      year,
      currentMonth.getMonth(),
      currentMonth.getDate()
    );
    setCurrentMonth(updatedDate);
    setSelectedDate(undefined);
    onSelect && onSelect(undefined);
  };

  const handleSelect: SelectSingleEventHandler = (selected) => {
    if (selected) {
      const newDate = new Date(selected);
      setSelectedDate(newDate);
      setCurrentMonth(selected);
      onSelect && onSelect(newDate);
    }
  };

  // Disable future dates
  const isDateDisabled = (date: Date) => isAfter(date, new Date());

  return (
    <div className={cn("space-y-4 p-3 flex flex-col items-center", className)}>
      {/* Month and Year Select */}
      <div className="flex items-center justify-center gap-2">
        <Select
          value={currentMonth.getMonth().toString()}
          onValueChange={(value) => handleMonthChange(parseInt(value, 10))}
        >
          <SelectTrigger className="w-32">
            <SelectValue
              placeholder={
                language === "fr" ? "Choisir le mois" : "Select Month"
              }
            >
              {currentMonth.toLocaleString(
                language === "fr" ? "fr-FR" : "en-US",
                { month: "long" }
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {Array.from({ length: 12 }, (_, i) => {
              const date = new Date(2024, i, 1);
              return (
                <SelectItem key={i} value={i.toString()}>
                  {date.toLocaleString(language === "fr" ? "fr-FR" : "en-US", {
                    month: "long",
                  })}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <Select
          value={currentMonth.getFullYear().toString()}
          onValueChange={(value) => handleYearChange(parseInt(value, 10))}
        >
          <SelectTrigger className="w-32">
            <SelectValue
              placeholder={
                language === "fr" ? "Choisir l'année" : "Select Year"
              }
            >
              {currentMonth.getFullYear()}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {getYears().map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* DayPicker Component */}
      <DayPicker
        showOutsideDays={showOutsideDays}
        month={currentMonth}
        onMonthChange={setCurrentMonth}
        selected={selectedDate}
        // @ts-expect-error Type 'SelectSingleEventHandler | undefined' is not assignable to type
        onSelect={props.mode === "single" ? handleSelect : undefined}
        disabled={isDateDisabled} // Disable future dates
        className={cn(className)}
        locale={language === "fr" ? fr : undefined}
        classNames={{
          months:
            "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
          month: "space-y-4",
          caption: "flex justify-center pt-1 relative items-center",
          caption_label: "text-sm font-medium",
          nav: "space-x-1 flex items-center",
          nav_button: cn(
            buttonVariants({ variant: "outline" }),
            "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
          ),
          nav_button_previous: "absolute left-1",
          nav_button_next: "absolute right-1",
          table: "w-full border-collapse space-y-1",
          head_row: "flex",
          head_cell:
            "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
          row: "flex w-full mt-2",
          cell: cn(
            "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
            props.mode === "range"
              ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
              : "[&:has([aria-selected])]:rounded-md"
          ),
          day: cn(
            buttonVariants({ variant: "ghost" }),
            "h-8 w-8 p-0 font-normal aria-selected:opacity-100"
          ),
          day_range_start: "day-range-start",
          day_range_end: "day-range-end",
          day_selected:
            "bg-[#12CBC4] text-primary-foreground hover:text-primary-foreground focus:bg-[#12CBC4] focus:text-primary-foreground",
          day_today: "bg-accent text-accent-foreground",
          day_outside:
            "day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground",
          day_disabled: "text-muted-foreground opacity-50",
          day_range_middle:
            "aria-selected:bg-accent aria-selected:text-accent-foreground",
          day_hidden: "invisible",
          ...classNames,
        }}
        components={{
          IconLeft: ({ className, ...props }) => (
            <ChevronLeft className={cn("h-4 w-4", className)} {...props} />
          ),
          IconRight: ({ className, ...props }) => (
            <ChevronRight className={cn("h-4 w-4", className)} {...props} />
          ),
        }}
        {...props}
      />
    </div>
  );
}
